@import '../../../node_modules/bootstrap/scss/bootstrap';

@import "variables";
@import "globals";
@import "animations";

.mainnav {
    height: 96px;
    font-size: xx-large;
    background-color: darken($dark-grey, 10%);
    margin-top: 40px;
    border-bottom: 2px solid darken($dark-grey, 15%);
    z-index: 2;
}

.subnav {
    display: block;
    background-color: black;
    z-index: 3;

    a {
        color: $light-grey;
        text-decoration: none;
    }
}

.mobilenav {
    top: 40px;
    padding-top: 10px;
    height: 40px;
    z-index: 2;

    .bg-dark-grey {
        background-color: darken($dark-grey, 15%);
    }

    a {
        color: $light-grey;
        text-decoration: none;
    }
}

.content {
    margin-top: 96 + 40px + 30px;
    padding: 20px;
    color: $light-grey;
    min-height: 50vh;
    background-color: lighten($dark-grey, 5%);
    background-image: url('./images/bg-accent.png');
    background-repeat: repeat-x;
    background-position: bottom;

    &::before,
    &::after {
        background-image: url('./images/bg-corner.png');
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        height: 128px;
        width: 128px;
        top: 170px;
    }

    &::before {
        background-position: left;
        left: 2px;
    }

    &::after {
        background-position: right;
        right: 2px;
        transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
    }
}

@include media-breakpoint-up(md) {
    .content {
        margin-top: 96 + 40px;
        padding: 0px;
        min-height: 65vh;

        &::before,
        &::after {
            top: 154px;
        }

        &::before {
            left: 15%;
        }

        &::after {
            right: 15%;
        }
    }
}

.footer {
    height: 180px;
    display: block;
    background: transparent;
    border-top: 2px solid lighten($mythos-red, 10%);
    position: relative;

    .footer-bar {
        background-color: $mythos-red;
        height: 32px;
        display: block;
        @extend .w-100;
        border-bottom: 2px solid darken($mythos-red, 10%);
    }

    .trademark {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    a {
        color: $mojang-red;

        &:hover {
            color: lighten($mojang-red, 20%);
        }
    }
}