@import 'variables';

@font-face {
    font-family: 'UpheavelTT';
    src: url('./fonts/upheavtt.ttf') format('truetype');
}

.font-normal {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

/* width */
::-webkit-scrollbar {
    padding-left: 0;
    width: 10px;
    margin-right: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $dark-grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: lighten($mythos-red, 5%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: lighten($mythos-red, 10%);
}

input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
}

html,
body {
    margin: 0;
    background-image: url('./images/coal_light.png');
    background-position: bottom;
}

.text-light-grey {
    color: $light-grey;
}

a {
    color: #888;
    text-decoration: none;

    &:hover {
        color: #ccc;
    }
}

.fs-0 {
    font-size: 3rem !important;
}

.fs-7 {
    font-size: 0.85rem !important;
}

.fs-8 {
    font-size: 0.75rem !important;
}

.fs-9 {
    font-size: 0.65rem !important;
}

.fs-10 {
    font-size: 0.55rem !important;
}

.m-btn-tab {
    background-image: url('./images/btn-tab.png');
    border: 0;
    background-color: none;
    background-repeat: no-repeat;
    background-size: contain;
    height: 64px;
    width: 256px;
    padding-top: 13px;
    @extend .header;
    color: darken($dark-tan, 10%);
    font-size: 1.5em;

    &:hover,
    &.active,
    &:active {
        color: lighten($dark-tan, 10%);
        background-image: url('./images/btn-tab-active.png');
    }
}

.m-btn {
    border-radius: 0 !important;
    color: #ddd;
    font-family: 'UpheavelTT', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2em;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);

    &.nav-link.active,
    &.m-btn-primary {
        background-color: $mythos-red;
        border-color: $mythos-red;
        border-left: lighten($mythos-red, 5%) 2px solid;
        border-top: lighten($mythos-red, 5%) 2px solid;
        border-right: darken($mythos-red, 5%) 2px solid;
        border-bottom: darken($mythos-red, 5%) 2px solid;
        box-shadow: rgba(0, 0, 0, 0.75) 1px 1px 2px 0px;

        &:hover,
        &.active {
            background-color: lighten($mythos-red, 5%);
            border-color: lighten($mythos-red, 5%);
        }
    }

    &.m-btn-secondary {
        background-color: $grey;
        border-left: lighten($grey, 5%) 2px solid;
        border-top: lighten($grey, 5%) 2px solid;
        border-right: darken($grey, 5%) 2px solid;
        border-bottom: darken($grey, 5%) 2px solid;
        box-shadow: rgba(0, 0, 0, 0.75) 1px 1px 2px 0px;
        @extend .text-light;

        &:hover {
            background-color: lighten($grey, 10%);
            border-color: lighten($grey, 10%);
        }
    }

    &.m-btn-info {
        background-color: $cyan;
        border-color: $cyan;
        border-left: lighten($cyan, 5%) 2px solid;
        border-top: lighten($cyan, 5%) 2px solid;
        border-right: darken($cyan, 5%) 2px solid;
        border-bottom: darken($cyan, 5%) 2px solid;
        box-shadow: rgba(0, 0, 0, 0.75) 1px 1px 2px 0px;

        &:hover {
            background-color: lighten($cyan, 10%);
            border-color: lighten($cyan, 10%);
        }
    }

    &.m-btn-success {
        background-color: $success;
        border-color: $success;
        border-left: lighten($success, 5%) 2px solid;
        border-top: lighten($success, 5%) 2px solid;
        border-right: darken($success, 5%) 2px solid;
        border-bottom: darken($success, 5%) 2px solid;
        box-shadow: rgba(0, 0, 0, 0.75) 1px 1px 2px 0px;

        &:hover {
            background-color: lighten($success, 5%);
            border-color: lighten($success, 5%);
        }
    }

    &.m-btn-danger {
        background-color: $danger;
        border-color: $danger;
        border-left: lighten($danger, 5%) 2px solid;
        border-top: lighten($danger, 5%) 2px solid;
        border-right: darken($danger, 5%) 2px solid;
        border-bottom: darken($danger, 5%) 2px solid;
        box-shadow: rgba(0, 0, 0, 0.75) 1px 1px 2px 0px;

        &:hover {
            background-color: darken($danger, 5%);
            border-color: darken($danger, 5%);
        }
    }

    &.m-btn-dark {
        background-color: $dark-grey;
        border-color: $dark-grey;
        border-left: lighten($dark-grey, 5%) 2px solid;
        border-top: lighten($dark-grey, 5%) 2px solid;
        border-right: darken($dark-grey, 5%) 2px solid;
        border-bottom: darken($dark-grey, 5%) 2px solid;
        box-shadow: rgba(0, 0, 0, 0.75) 1px 1px 2px 0px;
        @extend .text-light-grey;

        &:hover {
            background-color: lighten($dark-grey, 5%);
            border-color: lighten($dark-grey, 5%);
        }
    }

    &.m-btn-discord {
        background-color: $discord-blue;
        border-color: $discord-blue;
        border-left: lighten($discord-blue, 5%) 2px solid;
        border-top: lighten($discord-blue, 5%) 2px solid;
        border-right: darken($discord-blue, 5%) 2px solid;
        border-bottom: darken($discord-blue, 5%) 2px solid;
        box-shadow: rgba(0, 0, 0, 0.75) 1px 1px 2px 0px;
        color: #fff;

        &:hover {
            background-color: darken($discord-blue, 5%);
            border-color: darken($discord-blue, 5%);
        }
    }

    &.m-btn-link {
        background: transparent;
        color: $light-grey;
        border: 2px solid transparent;

        &:hover {
            background: rgba(0, 0, 0, 0.75);
            color: white;
        }
    }

    &:hover {
        color: #fff;
    }
}

.btn-link {
    background: transparent;
    border: none;
    padding: 0;
    text-decoration: none;
}

.dark-modal {
    .modal-content {
        border-radius: 0;
        background-color: $dark-grey;
        color: $light-grey;
        border-right: darken($dark-grey, 5%) 2px solid;
        border-bottom: darken($dark-grey, 5%) 2px solid;
        border-top: lighten($dark-grey, 5%) 2px solid;
        border-left: lighten($dark-grey, 5%) 2px solid;
        box-shadow: rgba(0, 0, 0, 0.75) 1px 1px 2px 0px;
    }
}

.pointer {
    cursor: pointer;
}

.bg-black {
    background-color: #000 !important;
}

.bg-mythos {
    background-color: $bg-mythos !important;
}

.bg-mojang {
    background-color: $bg-mojang !important;
}

.bg-discord {
    background-color: $bg-discord !important;
}

.bg-booster {
    background-color: $bg-booster !important;
}

.bg-tan {
    background-color: $tan !important;
    border: 4px solid $dark-tan;
    border-radius: 0 6px 6px 6px;
    color: darken($dark-tan, 10%);
    margin-top: -4px;

    input {
        background-color: lighten($dark-tan, 10%);
        border: 1px solid darken($dark-tan, 10%);
        color: white;
    }
}

.card {
    border-radius: 0;
    border-right: darken($dark-grey, 10%) 2px solid;
    border-bottom: darken($dark-grey, 10%) 2px solid;
    border-top: lighten($dark-grey, 5%) 2px solid;
    border-left: lighten($dark-grey, 5%) 2px solid;
}

.form-control {
    border-radius: 0;
}

.dropdown-toggle {
    padding: 0;
}

.dropdown-menu {
    border-radius: 0;
}

.vh-50 {
    height: 50vh;
}

.dark-bg {
    background-color: darken($dark-grey, 10%);
}

.bi-3 {
    border-left: darken($dark-grey, 5%) 2px solid;
    border-top: darken($dark-grey, 5%) 2px solid;
    border-bottom: lighten($dark-grey, 5%) 2px solid;
    border-right: lighten($dark-grey, 5%) 2px solid;
    box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
}

.bii-3 {
    border-left: lighten($dark-grey, 5%) 2px solid;
    border-top: lighten($dark-grey, 5%) 2px solid;
    border-bottom: darken($dark-grey, 5%) 2px solid;
    border-right: darken($dark-grey, 5%) 2px solid;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
}

.br-3 {
    border-radius: 3px;
}

.box-shadow {
    box-shadow: 5px 5px rgba(0, 0, 0, 0.15);
}

.box-shadow-inset {
    box-shadow: inset 5px 5px rgba(0, 0, 0, 0.15);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    filter: none !important;
}

.carousel .carousel-indicators button {
    background-color: #fff !important;
}

.carousel-filler {
    height: 400px;
    padding: auto 0px;
    width: 1000px;

    img {
        height: 100%;
        width: 100%;
    }
}

.myth-info {
    text-shadow: 2px 2px 0 #111;
}

.position-ab {
    position: absolute !important;
}

.header {
    font-family: 'UpheavelTT', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.15);
}

hr.divider {
    background-image: url('./images/divider.png');
    background-size: cover;
    border: 0;
    width: 100px;
    height: 36px;
}

hr.large-divider,
hr.large-divider-alt {
    border: 0;
    background-repeat: repeat-x;

    width: 100%;
    height: 9px;
}

hr.large-divider {
    background-image: url('./images/large-divider.png');
}

hr.large-divider-alt {
    background-image: url('./images/large-divider-alt.png');
}

.profile-picture {
    background-image: url('./images/bg-profile.png');
    height: 256px;
    width: 256px;
    padding: 25px 35px;
}

@include media-breakpoint-up(md) {
    .w-md-100 {
        width: 100% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-25 {
        width: 25% !important;
    }
}

@include media-breakpoint-down(md) {
    .m-btn-tab {
        height: 48px;
        width: 170px;
        font-size: 1em;
        margin-bottom: -2px;
    }

    .bg-tan {
        border-width: 2px;
    }
}